export const commonFields = [
  'start_date',
  'end_date',
  'level',
  'classification',
  'hourly_rate',
  'full_time',
  'contract_worked_time',
  'duree_complement',
  'comment',
  'referencePlanning',
];

export const CDIFormStates = [
  {
    type: 'NEW_CONTRACT',
    subtype: 'CDI',
    label: 'Nouveau contrat CDI',
    category: 'contract',
    excludedCommonField: ['end_date', 'duree_complement'],
    metadataFields: [
      'hasProbationPeriod',
      'probationPeriodDurationQuantity',
      'probationPeriodDurationUnit',
      'hasFewWeeklyHoursWaiver',
      'isAnnexeSeven',
    ],
  },
  {
    type: 'TEMP_TO_PERMANENT_AMENDMENT',
    subtype: 'CDD_en_CDI',
    label: 'Passage de CDD en CDI',
    category: 'contract',
    excludedCommonField: ['end_date', 'duree_complement'],
    metadataFields: [
      'hasProbationPeriod',
      'probationPeriodDurationQuantity',
      'probationPeriodDurationUnit',
      'hasFewWeeklyHoursWaiver',
    ],
  },
  {
    type: 'HOURS_MODIFICATION_AMENDMENT',
    subtype: 'CDI',
    label: "Avenant de modification d'heures",
    category: 'amendment',
    excludedCommonField: [
      'end_date',
      'duree_complement',
      'level',
      'classification',
      'hourly_rate',
    ],
    metadataFields: ['hasFewWeeklyHoursWaiver'],
  },
  {
    type: 'EXTRA_HOURS_AMENDMENT',
    subtype: 'complement_heure',
    label: "Avenant de Complément d'heure",
    category: 'amendment',
    excludedCommonField: [''],
    metadataFields: [
      'temporaryContractJustification',
      'replacedEmployeeName',
      'hasFewWeeklyHoursWaiver',
    ],
  },
  {
    type: 'SALARY_AMENDMENT',
    subtype: 'CDI',
    label: 'Avenant de changement de salaire',
    category: 'amendment',
    excludedCommonField: ['end_date', 'duree_complement'],
    metadataFields: [],
  },
  {
    type: 'JOB_TITLE_AMENDMENT',
    subtype: 'CDI',
    label: 'Avenant de changement de poste',
    category: 'amendment',
    excludedCommonField: ['end_date', 'duree_complement'],
    metadataFields: [
      'hasProbationPeriod',
      'probationPeriodDurationQuantity',
      'probationPeriodDurationUnit',
    ],
  },
];

export const CDDFormStates = [
  {
    type: 'NEW_CONTRACT',
    label: 'Nouveau contrat CDD',
    category: 'contract',
    excludedCommonField: ['duree_complement'],
    metadataFields: [
      'replacementEmployeeName',
      'temporaryContractJustification',
      'hasFewWeeklyHoursWaiver',
    ],
  },
  {
    type: 'TEMPORARY_CONTRACT_RENEWAL',
    label: 'Renouvellement de contrat CDD',
    category: 'contract',
    excludedCommonField: ['duree_complement'],
    metadataFields: [
      'replacementEmployeeName',
      'temporaryContractJustification',
      'hasFewWeeklyHoursWaiver',
    ],
  },
];

export const findCurrentState = (contractType, metadataType) => {
  return (
    (contractType === 'CDI' ? CDIFormStates : CDDFormStates).find((item) => {
      return item.type === metadataType;
    }) || {}
  );
};

export const getSubtype = (
  contractType,
  metadataType,
  temporaryContractJustification = '',
) => {
  return contractType === 'CDI'
    ? findCurrentState(contractType, metadataType)?.subtype
    : temporaryContractJustification;
};

export const typesTranslation = {
  NEW_CONTRACT: 'Nouveau contrat CDI / CDD',
  TEMP_TO_PERMANENT_AMENDMENT: 'Passage de CDI en CDD',
  HOURS_MODIFICATION_AMENDMENT: "Avenant de modification d'heures",
  EXTRA_HOURS_AMENDMENT: "Avenant de Complément d'heure",
  SALARY_AMEMDMENT: 'Avenant de changement de salaire',
  JOB_TITLE_AMEMDMENT: 'Avenant de changement de poste',
  TEMPORARY_CONTRACT_RENEWAL: 'Renouvellement de contrat CDD',
};

export const defaultMetadataFields = {
  hasProbationPeriod: false,
  probationPeriodDurationQuantity: false,
  probationPeriodDurationUnit: false,
  temporaryContractJustification: false,
  replacedEmployeeName: false,
  hasFewWeeklyHoursWaiver: false,
  isAnnexeSeven: false,
};
