import { commonFields, findCurrentState } from './state';

export const checkContractCDD = (contractType) => {
  if (contractType === 'CDD') {
    return true;
  }
  return false;
};

export const checkContractSubtype = (contractSubtype) => {
  if (contractSubtype === 'complement_heure') {
    return true;
  }
  return false;
};

export const checkContractCDI = (contractType) => {
  if (contractType === 'CDI') {
    return true;
  }
  return false;
};

export const shouldDisplayInput = (contractType, metadataType, field) => {
  const currentState = findCurrentState(contractType, metadataType);

  if (!contractType) return false;

  const isCommonFieldAndNotExcluded =
    commonFields.includes(field) &&
    !currentState?.excludedCommonField?.includes(field);

  const isMetadataFieldAndNotExcluded = currentState?.metadataFields?.includes(
    field,
  );

  return isCommonFieldAndNotExcluded || isMetadataFieldAndNotExcluded;
};
