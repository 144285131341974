import { required, useInput, useQuery } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useEffect } from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import {
  CDDFormStates,
  CDIFormStates,
  defaultMetadataFields,
  getSubtype,
} from './state';

// eslint-disable-next-line react/prop-types
const ContractTypeSelect = ({ source, resource, cleanerId }) => {
  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useInput({ source, validate: required() });
  const form = useForm();
  const { values } = useFormState();
  const { data: contracts } = useQuery({
    type: 'getList',
    resource,
    payload: {
      filter: { cleaner_id: cleanerId },
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'start_date', order: 'DESC' },
    },
  });

  const contractType = values.contract_type;
  const lastCDDContract = contracts?.find((contract) => {
    return contract.contract_type === 'CDD';
  });

  useEffect(() => {
    if (!values?.metadata?.type) return;
    if (contractType === 'CDI') {
      form.change(
        'contract_subtype',
        getSubtype(contractType, values?.metadata?.type),
      );
    }
    if (
      contractType === 'CDD' &&
      values?.metadata?.type === 'TEMPORARY_CONTRACT_RENEWAL'
    ) {
      form.change(
        'metadata.temporaryContractJustification',
        lastCDDContract?.metadata?.temporaryContractJustification,
      );
      form.change('contract_subtype', lastCDDContract?.contract_subtype);
      form.change(
        'metadata.replacedEmployeeName',
        lastCDDContract?.metadata?.replacedEmployeeName,
      );
    }
  }, [contractType, values?.metadata?.type]);

  const choice = contractType === 'CDI' ? CDIFormStates : CDDFormStates;

  const resetUnusedFields = (metadataFields) => {
    const allMetadataFields = Object.keys(defaultMetadataFields);

    allMetadataFields.forEach((field) => {
      if (!metadataFields?.includes(field)) {
        form.change(`metadata.${field}`, undefined);
      }
    });
    if (values?.metadata?.type !== 'EXTRA_HOURS_AMENDMENT') {
      form.change('metadata.temporaryContractJustification', undefined);
      form.change('metadata.replacedEmployeeName', undefined);
      form.change('duree_complement', undefined);
    }
  };

  const onChangeType = (type) => {
    onChange({
      ...value,
      type,
    });
    const currentState = choice.find((item) => {
      return item?.type === type;
    });
    if (currentState) {
      resetUnusedFields(currentState.metadataFields);
    }
  };

  return (
    contractType && (
      <>
        <FormControl
          sx={{ m: 1, minWidth: 120 }}
          error={touched && Boolean(error)} // Gestion de l'état d'erreur
        >
          <InputLabel id="contract-metadata-type-label">Type</InputLabel>
          <Select
            variant="filled"
            label="Type de contrat / avenant"
            labelId="contract-metadata-type-label"
            value={value?.type || ''}
            style={{ width: 300, paddingLeft: '0.5rem' }}
            required
            onChange={(e) => {
              return onChangeType(e.target.value);
            }}
          >
            <ListSubheader>Contrats</ListSubheader>
            {choice
              .filter((item) => {
                return item.category === 'contract';
              })
              .map((item) => {
                return <MenuItem value={item?.type}>{item.label}</MenuItem>;
              })}
            {choice.filter((item) => {
              return item.category === 'amendment';
            }).length > 0 && <ListSubheader>Avenants</ListSubheader>}
            {choice
              .filter((item) => {
                return item.category === 'amendment';
              })
              .map((item) => {
                return <MenuItem value={item.type}>{item.label}</MenuItem>;
              })}
          </Select>
          {touched && error && (
            <FormHelperText>Ce champ est requis</FormHelperText>
          )}
        </FormControl>
      </>
    )
  );
};

export default ContractTypeSelect;
