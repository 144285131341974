import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';
import { ReferenceManyField, useDataProvider, useTranslate } from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import { ProcedureButton } from '../button';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      justifyContent: 'space-between',
      textAlign: 'center',
      alignItems: 'center',
      minWidth: 105,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      fontSize: 14,
    },
    active: {
      backgroundColor: theme.palette.procedureStatus.active,
    },
    draft: {
      backgroundColor: theme.palette.procedureStatus.draft,
    },
    expired: {
      backgroundColor: theme.palette.procedureStatus.expired,
    },
    finished: {
      backgroundColor: theme.palette.procedureStatus.finished,
    },
    refused: {
      backgroundColor: theme.palette.procedureStatus.refused,
    },
    unknown: {
      backgroundColor: theme.palette.procedureStatus.unknown,
    },
  };
});

export const ProcedureField = ({ contract, data, ids }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  let procedureStatus = 'unknown';
  if (data && ids) {
    const first = ids[0];
    if (data[first]) {
      procedureStatus = data[first].procedure_status;
    }
  }

  const cleanerContractData = useQuery({
    queryKey: [`${contract.id}-cleanerContract`],
    queryFn: () => {
      return dataProvider
        .getOne('cleaner_contract', { id: contract.id })
        .then((result) => {
          return result.data;
        });
    },
    refetchInterval: 5 * 60 * 1000,
  });

  const procedureId = cleanerContractData.data
    ? cleanerContractData.data.procedure_id
    : contract.procedure_id;

  const fetchProcedureStatus = useQuery({
    queryKey: [`${contract.id}-yousignProcedure`],
    queryFn: () => {
      return dataProvider
        .getOne('yousign_procedure', {
          procedure_id: procedureId,
        })
        .then((result) => {
          return result.data.procedureStatus;
        });
    },
    refetchInterval: 5 * 60 * 1000,
    refetchOnWindowFocus: true,
    enabled: procedureId !== null && procedureId !== undefined,
  });

  return (
    <div>
      <Chip
        reference="cleaner"
        className={clsx(
          classes.root,
          classes[
            fetchProcedureStatus.data
              ? fetchProcedureStatus.data
              : procedureStatus
          ],
        )}
        label={translate(
          `components.field.procedureStatus.${
            fetchProcedureStatus.data
              ? fetchProcedureStatus.data
              : procedureStatus
          }`,
        )}
        color="primary"
        justifyContent="space-between"
        textAlign="center"
        alignItems="center"
      />
      <ProcedureButton
        status={
          fetchProcedureStatus.data
            ? fetchProcedureStatus.data
            : procedureStatus
        }
        contract={
          cleanerContractData.data ? cleanerContractData.data : contract
        }
      />
    </div>
  );
};

ProcedureField.propTypes = {
  contract: PropTypes.object,
  data: PropTypes.object,
  ids: PropTypes.array,
};

ProcedureField.defaultProps = {
  contract: {},
  data: undefined,
  ids: undefined,
};

const ProcedureReferenceField = ({ record, ...props }) => {
  const procedureId = record.procedure_id;

  if (!procedureId) {
    return <ProcedureField contract={record} />;
  }

  const newRecord = { ...record, id: procedureId };

  return (
    <ReferenceManyField
      {...props}
      record={newRecord}
      reference="yousign_procedure"
      target="procedure_id"
    >
      <ProcedureField contract={record} />
    </ReferenceManyField>
  );
};

ProcedureReferenceField.propTypes = {
  record: PropTypes.object,
};

ProcedureReferenceField.defaultProps = {
  record: {},
};

export default ProcedureReferenceField;
