import { useInput } from 'react-admin';
import { TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowForward from '@material-ui/icons/ArrowForward';
import React from 'react';
import { useForm } from 'react-final-form';
import { durationToSeconds, secondsToDuration } from './utils';

// eslint-disable-next-line react/prop-types
const ContractWorkTimeInput = ({ type }) => {
  const form = useForm();
  const totalWorkTimeInput = useInput({ source: 'contract_worked_time' });
  const additionalTimeInput = useInput({ source: 'duree_complement' });
  const isExtraHours = type === 'EXTRA_HOURS_AMENDMENT';

  const needsWaiver = (total) => {
    return total <= 16 * 60 * 60;
  };

  const initialBaseWorkTime = () => {
    const totalWorkTimeInSeconds = durationToSeconds(
      totalWorkTimeInput.input.value || '00:00:00',
    );
    const additionalTimeInSeconds = durationToSeconds(
      additionalTimeInput.input.value || '00:00:00',
    );
    const baseTimeInSeconds = totalWorkTimeInSeconds - additionalTimeInSeconds;
    return secondsToDuration(Math.max(baseTimeInSeconds, 0));
  };

  const handleBaseTimeChange = (event) => {
    const newBaseTimeInSeconds = durationToSeconds(event.target.value);
    const additionalTimeInSeconds = durationToSeconds(
      additionalTimeInput.input.value || '00:00:00',
    );
    const newTotalWorkTimeInSeconds =
      newBaseTimeInSeconds + additionalTimeInSeconds;
    totalWorkTimeInput.input.onChange(
      secondsToDuration(newTotalWorkTimeInSeconds),
    );
    form.change(
      'metadata.hasFewWeeklyHoursWaiver',
      needsWaiver(newTotalWorkTimeInSeconds),
    );
    form.change('full_time', newTotalWorkTimeInSeconds >= 35 * 60 * 60);
  };

  const handleAdditionalTimeChange = (event) => {
    const newAdditionalTimeInSeconds = durationToSeconds(event.target.value);
    const baseTimeInSeconds = durationToSeconds(initialBaseWorkTime());
    const newTotalWorkTimeInSeconds =
      baseTimeInSeconds + newAdditionalTimeInSeconds;
    additionalTimeInput.input.onChange(event.target.value);
    totalWorkTimeInput.input.onChange(
      secondsToDuration(newTotalWorkTimeInSeconds),
    );
    form.change(
      'metadata.hasFewWeeklyHoursWaiver',
      needsWaiver(newTotalWorkTimeInSeconds),
    );
    form.change('full_time', newTotalWorkTimeInSeconds >= 35 * 60 * 60);
  };

  const handleTotalWorkTimeChange = (event) => {
    const total = durationToSeconds(event.target.value);
    totalWorkTimeInput.input.onChange(event.target.value);
    form.change('metadata.hasFewWeeklyHoursWaiver', needsWaiver(total));
    form.change('full_time', total >= 35 * 60 * 60);
  };

  return (
    <section
      style={{
        width: '100%',
        display: 'flex',
        gap: 30,
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'baseline',
      }}
    >
      {isExtraHours ? (
        <>
          <TextField
            disabled
            InputLabelProps={{ shrink: true }}
            variant="filled"
            label="Base horaire"
            defaultValue={initialBaseWorkTime()}
            onChange={handleBaseTimeChange}
            style={{ flex: 1 }}
          />
          <AddIcon />
          <TextField
            variant="filled"
            label="Durée complément"
            value={additionalTimeInput.input.value || '00:00:00'}
            onChange={handleAdditionalTimeChange}
            style={{ flex: 1 }}
          />
          <ArrowForward />
          <TextField
            variant="filled"
            label="Total temps de travail"
            value={totalWorkTimeInput.input.value || '00:00:00'}
            disabled
            style={{ flex: 1 }}
          />
        </>
      ) : (
        <TextField
          variant="filled"
          label="Total temps de travail"
          value={totalWorkTimeInput.input.value}
          onChange={handleTotalWorkTimeChange}
        />
      )}
    </section>
  );
};

export default ContractWorkTimeInput;
