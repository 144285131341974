import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
  showNotification,
} from 'react-admin';

export const getError = async (response) => {
  const error = await response.json();

  return typeof error === 'string'
    ? error
    : error.message || 'aor.notification.http_error';
};

const zapierStoreUrl = 'https://store.zapier.com/api/records';
const enqueueCreateSignatureWebHookUrl =
  'https://hooks.zapier.com/hooks/catch/3450616/2rs9pdq/';
const revokeSignatureRequestWebHookUrl =
  'https://hooks.zapier.com/hooks/catch/3450616/2reylpa/';
const storeSecret = 'e2824aa4-8d84-46b1-8b31-66edb12a8768';

export const sendSignature = async (contract) => {
  const response = await fetch(
    `${enqueueCreateSignatureWebHookUrl}?contractId=${contract.id}`,
    {
      method: 'GET',
    },
  );

  if (response.status < 200 || response.status >= 300) {
    throw new Error(await getError(response));
  }

  return response.json();
};

export const cancelSignature = async (contract) => {
  const body = {};
  body[`${contract.id}`] = null;

  const response = await fetch(`${zapierStoreUrl}?secret=${storeSecret}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(body),
  });

  if (response.status < 200 || response.status >= 300) {
    throw new Error(await getError(response));
  }

  return response.json();
};

export const revokeSignature = async (contract, status) => {
  const response = await fetch(
    `${revokeSignatureRequestWebHookUrl}?signatureRequestId=${contract.procedure_id}&signatureRequestStatus=${status}`,
    {
      method: 'GET',
    },
  );

  if (response.status < 200 || response.status >= 300) {
    throw new Error(await getError(response));
  }

  return response.json();
};

export const existsInStorage = async (key) => {
  const response = await fetch(
    `${zapierStoreUrl}?secret=${storeSecret}&key=${key}`,
    {
      method: 'GET',
    },
  );

  const storeResponse = await response.json();

  return Object.prototype.hasOwnProperty.call(storeResponse, key);
};

export function* signContract({
  payload: { contract, status, isContractIdInStorage },
}) {
  try {
    yield put({ type: FETCH_START });
    if (isContractIdInStorage) {
      yield call(cancelSignature, contract);
    } else if (
      status === 'active' ||
      status === 'draft' ||
      status === 'finished'
    ) {
      yield call(revokeSignature, contract, status);
    } else {
      yield call(sendSignature, contract);
    }

    yield put({ type: FETCH_END });
    yield put(showNotification('notification.sign_contract.success', 'info'));
  } catch (error) {
    yield put({ type: FETCH_ERROR });
    yield put(showNotification(error.message, 'warning'));
  }
}

export default function* watch() {
  yield takeLatest('SAGA/SIGN_CONTRACT', signContract);
}
