import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, CircularProgress } from '@material-ui/core';

const frenchTranslationDay = {
  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  saturday: 'Samedi',
  sunday: 'Dimanche',
};

const weekSuffixes = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
];

const formatTime = (time) => {
  return time
    .replace(/^0?(\d+):00$/, '$1h')
    .replace(/^0?(\d+):(\d+)$/, '$1h$2');
};

const formatTimeSlot = (timeSlot) => {
  return `${formatTime(timeSlot.start)}-${formatTime(timeSlot.end)}`;
};

const formatTitle = (weekIndex) => {
  return `Semaine ${weekSuffixes[weekIndex % weekSuffixes.length]}`;
};

const generateWeekPlanning = (week) => {
  return Object.keys(week)
    .filter((day) => {
      return !!week[day] && week[day].length > 0;
    })
    .map((day) => {
      return `${frenchTranslationDay[day]}: ${week[day]
        .sort((a, b) => {
          return a.start.localeCompare(b.start);
        })
        .map(formatTimeSlot)
        .join(', ')}`;
    })
    .join('\n');
};

const generateCompletePlanning = (planning) => {
  let result = '';

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const index in planning) {
    const week = planning[index];
    const title = planning.length > 1 ? formatTitle(Number(index)) : '';
    result += `${title}\n${generateWeekPlanning(week)}\n`;
  }

  return result.trim();
};

const parseReferencePlanning = (planning) => {
  let result;
  try {
    result = generateCompletePlanning([JSON.parse(planning?.substring(8))])
      .split('\n')
      .map((day) => {
        return <li key={day}>{day}</li>;
      });
  } catch (error) {
    console.error('Error while parsing reference planning', error);
    result = [];
  }
  return result;
};

// eslint-disable-next-line import/prefer-default-export,react/prop-types,consistent-return
export const ReferencePlanning = ({ cleanerId, contractId, date, type }) => {
  const { data: planning, isLoading, isError } = useQuery({
    queryKey: ['planning', { cleanerId, contractId, date }],
    queryFn: () => {
      return fetch(
        `https://caredataparser.onrender.com/generate-reference-planning`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            cleanerId,
            contractId,
            date,
          }),
        },
      ).then((response) => {
        return response.json();
      });
    },
    enabled: !!contractId,
  });

  const { data: originalTemporaryContract } = useQuery({
    queryKey: ['originalTemporaryContract'],
    queryFn: () => {
      if (contractId) {
        return Promise.resolve(null);
      }
      return fetch(
        `https://caredataparser.onrender.com/get-original-temporary-contract-for-renewal`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contractId,
          }),
        },
      ).then((response) => {
        return response.json();
      });
    },
    enabled: !!contractId,
  });

  if (isLoading) {
    return (
      <div
        style={{
          display: 'inline-block',
          paddingLeft: 20,
          paddingTop: 15,
        }}
      >
        <CircularProgress size={20} />
      </div>
    );
  }

  if (isError) {
    return <div>Erreur lors de la récupération du planning de référence</div>;
  }

  if (planning)
    return (
      <section style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Card style={{ padding: '1rem', width: 'max-content' }}>
          <h3 style={{ fontWeight: 500 }}>
            Planning de référence au{' '}
            {new Date(Date.parse(date)).toLocaleString('fr-FR', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })}
          </h3>
          <ul>
            {parseReferencePlanning(planning)}
          </ul>
        </Card>
        {type === 'TEMPORARY_CONTRACT_RENEWAL' && !!originalTemporaryContract && (
          <Card style={{ padding: '1rem' }}>
            <h3 style={{ fontWeight: 400, textAlign: 'center' }}>
              Contrat CDD initial
            </h3>
            <p>{originalTemporaryContract.id}</p>
            <p>
              Du{' '}
              {new Date(
                Date.parse(originalTemporaryContract?.period.start),
              ).toLocaleString('fr-FR', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })}{' '}
              au{' '}
              {new Date(
                Date.parse(originalTemporaryContract?.period.end),
              ).toLocaleString('fr-FR', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })}
            </p>
          </Card>
        )}
      </section>
    );
};
