import { useInput } from 'react-admin';
import { useFormState } from 'react-final-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React from 'react';
import { findCurrentState } from './state';

// eslint-disable-next-line react/prop-types
const ContractMetadataForm = ({ source }) => {
  const {
    input: { value, onChange },
  } = useInput({ source });

  const { values } = useFormState();

  const handleFieldChange = (field, fieldValue) => {
    onChange({
      ...value,
      [field]: fieldValue,
    });
  };

  const contractType = values.contract_type;

  const { metadataFields } = findCurrentState(contractType, value?.type);

  const displayField = (field) => {
    return metadataFields?.includes(field);
  };

  return (
    <FormControl component="fieldset">
      <FormGroup>
        {displayField('hasProbationPeriod') && (
          <FormControlLabel
            style={{ marginTop: 20 }}
            control={
              <Checkbox
                checked={value?.hasProbationPeriod}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  onChange({
                    ...value,
                    hasProbationPeriod: isChecked,
                    probationPeriodDurationQuantity: isChecked
                      ? value?.probationPeriodDurationQuantity
                      : '',
                    probationPeriodDurationUnit: isChecked
                      ? value?.probationPeriodDurationUnit
                      : '',
                  });
                }}
              />
            }
            label="Inclure une période d'essai"
          />
        )}
        {value?.hasProbationPeriod && (
          <div
            style={{
              display: 'flex',
              marginTop: 20,
              flexDirection: 'row',
              gap: 5,
            }}
          >
            <TextField
              placeholder="Nombre de jours / mois de période d'essai"
              style={{ width: 100 }}
              type="number"
              value={value?.probationPeriodDurationQuantity || ''}
              onChange={(e) => {
                return handleFieldChange(
                  'probationPeriodDurationQuantity',
                  e.target.value,
                );
              }}
            />
            <Select
              displayEmpty
              labelId="probation-period-unit-label"
              value={value?.probationPeriodDurationUnit || ''}
              onChange={(e) => {
                return handleFieldChange(
                  'probationPeriodDurationUnit',
                  e.target.value,
                );
              }}
            >
              <MenuItem disabled value="">
                <em>Jours / Mois</em>
              </MenuItem>
              <MenuItem value="DAY">Jours</MenuItem>
              <MenuItem value="MONTH">Mois</MenuItem>
            </Select>
          </div>
        )}

        {displayField('isAnnexeSeven') && (
          <FormControlLabel
            control={
              <Checkbox
                checked={value?.isAnnexeSeven || false}
                onChange={(e) => {
                  return handleFieldChange('isAnnexeSeven', e.target.checked);
                }}
              />
            }
            label="Article 7 ?"
          />
        )}
        {displayField('hasFewWeeklyHoursWaiver') && (
          <FormControlLabel
            control={
              <Checkbox
                checked={value?.hasFewWeeklyHoursWaiver || false}
                onChange={(e) => {
                  return handleFieldChange(
                    'hasFewWeeklyHoursWaiver',
                    e.target.checked,
                  );
                }}
              />
            }
            label="Nécessite une dérogation pour moins de 16h ? "
          />
        )}
      </FormGroup>
    </FormControl>
  );
};

export default ContractMetadataForm;
