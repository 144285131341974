import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import { existsInStorage } from '../../saga/signContract';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minWidth: 85,
      marginLeft: theme.spacing(1),
    },
  };
});

export const ProcedureButton = ({ contract, status }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const isContractIdInStorage = useQuery({
    queryKey: [`is${contract.id}InStorage`],
    queryFn: () => {
      return existsInStorage(contract.id).then((result) => {
        return result;
      });
    },
    refetchInterval: 60 * 1000 * 5,
  });

  const onProcedureAction = useCallback(
    (event) => {
      event.stopPropagation();
      dispatch({
        type: 'SAGA/SIGN_CONTRACT',
        payload: {
          contract,
          status,
          isContractIdInStorage: isContractIdInStorage.data,
        },
      });
    },
    [dispatch, contract, status, isContractIdInStorage.data],
  );

  const signAction = ['active', 'draft'].includes(status) ? 'revoke' : 'sign';

  return isContractIdInStorage.data !== null ? (
    status !== 'finished' && (
      <Button
        className={classes.root}
        color="primary"
        onClick={(event) => {
          onProcedureAction(event);
        }}
        disabled={contract.metadata === null}
      >
        {translate(
          `resources.cleaner_contract.actions.${
            isContractIdInStorage.data ? 'cancelProcedure' : signAction
          }`,
        )}
      </Button>
    )
  ) : (
    <Button className={classes.root} color="primary">
      Loading...
    </Button>
  );
};

ProcedureButton.propTypes = {
  contract: PropTypes.object,
  status: PropTypes.string,
};

ProcedureButton.defaultProps = {
  contract: {},
  status: '',
};

export default ProcedureButton;
