export const durationToSeconds = (duration) => {
  const [hours, minutes, seconds] = duration.split(':').map(Number);
  return hours * 3600 + minutes * 60 + (seconds || 0);
};

export const secondsToDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    remainingSeconds.toString().padStart(2, '0'),
  ].join(':');
};

// Hello Ouassim,
//   Je te devais une explication sur les règles pour la période d'essai :
// CDI
// Période d'essai obligatoire Si 1e contrat chez Cleany
// Période d'essai obligatoire Si ce n'est pas le 1e contrat + Ca suit CDD dont la durée n'excède pas la durée de la période d'essai (Auquel cas, la durée des précédents contrats sans interruption se déduisent de la période d'essai)
// Période d'essai obligatoire Si ce n'est pas le 1e contrat + interruption entre les précédents contrats et celui ci
// Pas de période d'essai si suit un précédent contrat dont la durée est supérieure à la durée de période d'essai
// CDD
// Période d'essai obligatoire selon les règles suivantes :
// Pour un CDD de 6 mois ou plus
// Durée = 1 mois
// Non renouvelable
// Pour un CDD de moins de 6 mois
// Durée = 1 jour par semaine de contrat (ex, pour un contrat de 4 semaines, alors 4 jours de période d'essai)
// Plafonné à 2 semaines
// Non renouvelable
// Pas de période d'essai en cas de renouvellement de CDD
// Si tu as des questions, n'hésite pas à me solliciter.

export const isFirstCDIContract = (contract, contractsList) => {
  return contract.type === 'CDI' && contractsList.length === 0;
};

export const hasProbationPeriod = (contract, contractsList) => {
  return (
    isFirstCDIContract(contract, contractsList) ||
    (contract.type === 'CDI' &&
      contractsList.length > 0 &&
      contractsList[0].type === 'CDD' &&
      durationToSeconds(contractsList[0].duration) <=
        durationToSeconds(contract.trialPeriodDuration)) ||
    (contract.type === 'CDI' &&
      contractsList.length > 0 &&
      contractsList[0].type === 'CDI' &&
      durationToSeconds(contractsList[0].duration) <=
        durationToSeconds(contract.trialPeriodDuration) &&
      contractsList[0].endDate < contract.startDate) ||
    (contract.type === 'CDI' &&
      contractsList.length > 0 &&
      contractsList[0].type === 'CDI' &&
      durationToSeconds(contractsList[0].duration) >
        durationToSeconds(contract.trialPeriodDuration))
  );
};

export const trialPeriodRules = {
  CDI: {
    mandatory: [
      { condition: 'First contract with Cleany' },
      {
        condition: 'Not the first contract + Follows a CDD',
        additionalInfo:
          "Duration of CDD does not exceed the trial period duration. In this case, previous uninterrupted contracts' durations are deducted from the trial period.",
      },
      {
        condition:
          'Not the first contract + Gap between previous contracts and the current one',
      },
    ],
    notRequired: [
      {
        condition:
          'Follows a previous contract with a duration exceeding the trial period duration',
      },
    ],
  },
  CDD: {
    mandatory: [
      {
        condition: 'CDD of 6 months or longer',
        duration: '1 month',
        renewable: false,
      },
      {
        condition: 'CDD of less than 6 months',
        duration:
          '1 day per week of contract (e.g., 4 weeks = 4 days trial period)',
        cappedAt: '2 weeks',
        renewable: false,
      },
    ],
    notRequired: [
      {
        condition: 'In case of CDD renewal',
      },
    ],
  },
};
