import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { ReferenceManyField } from 'react-admin';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      justifyContent: 'space-between',
      textAlign: 'center',
      alignItems: 'center',
      minWidth: 105,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      fontSize: 14,
    },
    active: {
      backgroundColor: theme.palette.procedureStatus.active,
    },
    draft: {
      backgroundColor: theme.palette.procedureStatus.draft,
    },
    expired: {
      backgroundColor: theme.palette.procedureStatus.expired,
    },
    finished: {
      backgroundColor: theme.palette.procedureStatus.finished,
    },
    refused: {
      backgroundColor: theme.palette.procedureStatus.refused,
    },
    unknown: {
      backgroundColor: theme.palette.procedureStatus.unknown,
    },
  };
});

export const yousignProcedureURL = (id, index) => {
  if (!id) {
    return null;
  }
  return parseInt(index, 10) >= 12233
    ? `https://yousign.app/auth/workspace/requests/${id}`
    : `https://webapp.yousign.com/${id}`;
};

export const ProcedureLink = ({ contract, data, ids }) => {
  const classes = useStyles();

  let procedureStatus = 'unknown';
  if (contract && data && ids) {
    const first = ids[0];
    if (data[first]) {
      procedureStatus = data[first].procedure_status;
    }
  }

  return (
    ids?.length > 0 &&
    data[ids[0]] && (
      <Button
        className={classes.root}
        color="primary"
        onClick={(event) => {
          event.stopPropagation();
          if (procedureStatus !== 'unknown') {
            window.open(
              yousignProcedureURL(data[ids[0]].procedure_id, data[ids[0]].id),
              '_blank',
            );
          }
        }}
      >
        {ids[0]}
      </Button>
    )
  );
};

ProcedureLink.propTypes = {
  contract: PropTypes.object,
  data: PropTypes.object,
  ids: PropTypes.array,
};

ProcedureLink.defaultProps = {
  contract: {},
  data: undefined,
  ids: undefined,
};

const ProcedureLinkField = ({ record, ...props }) => {
  const procedureId = record.procedure_id;

  if (!procedureId) {
    return <ProcedureLink contract={record} />;
  }

  const newRecord = { ...record, id: procedureId };

  return (
    <ReferenceManyField
      {...props}
      record={newRecord}
      reference="yousign_procedure"
      target="procedure_id"
    >
      <ProcedureLink contract={record} />
    </ReferenceManyField>
  );
};

ProcedureLinkField.propTypes = {
  record: PropTypes.object,
};

ProcedureLinkField.defaultProps = {
  record: {},
};

export default ProcedureLinkField;
